<template>
  <div>
    <BetTool
      id="bet-tool"
      v-observe-visibility="visibilityChanged"
      :colors-config="colorsConfig"
      :is-premium-user="!!user.premiumAccount"
      :seasons="seasons"
      @premium-disabled-clicked="onPremiumDisabledClicked($event)"
      :header="false"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { widgetSeccionVista } from '@/utils/analytics';
import { mapGetters, mapMutations, mapState } from 'vuex';
import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

export default {
  name: 'Perzonalizado',
  components: {
    BetTool: () => import('@/views/BetToolMenu'),
  },
  props: {
    colorsConfig: {
      type: Object,
      default: () => {
        return {
          logo: 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png',
          color: '#FFFFFF',
          headerBackground: '#242424',
          headerColor: '#FFFFFF',
          headerBorderColor: '#6EB913',
          header2Background: '#3b3b3b',
          header2Color: '#FFFFFF',
          subHeaderBackground: '#cce8b5',
          subHeaderColor: '#353535',
          type: 1,
          team_id: 0,
          tournaments: false,
          categories: 'all',
        };
      },
    },
    /**
     * Seasons objects to fetch games
     * {Array<{id: number, type: number}>} seasons
     */
    seasons: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapGetters('loginGeneral', ['isLoggedIn', 'getIsGolstats']),
  },
  methods: {
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapMutations('general', ['setSectionAnalytics']),
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        const sectionName = entry.target.id;
        widgetSeccionVista({
          value: sectionName,
          team: this.$route.params.team,
          is_premium: this.user.premiumAccount,
          golstats: this.getIsGolstats,
        });
      }
    },
    onPremiumDisabledClicked(message) {
      this.setSectionAnalytics(message);
      this.SET_PREMIUM_MESSAGE(true);
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  max-width: 1268px;
  height: auto;
  background: white;
}
</style>
